import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/Layout"
import Image from "gatsby-image"
import ReadLink from "../components/read-link"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const FlexDiv = styled.div`
  h2 {
    margin: 1rem 0;
  }
  @media (min-width:800px){
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      margin: 0;
    }
  }
`
const options = {}

const ProjectTemplate = ({ data: { contentfulProjects: project } }) => (
  <Layout postTitle={project.title}>
    <h1>{project.title}</h1>
    <FlexDiv>
      <h2>
        {project.subtitle}
      </h2>
      <a href={project.url}>View site &rarr;</a>
    </FlexDiv>
    
    <Image
          fluid={project.featuredImage.fluid}
          key={project.featuredImage.src}
          alt={project.title}
        />
    <div>{renderRichText(project.description, options)}</div>
    <ReadLink to="/">&larr; back to home</ReadLink>
  </Layout>
)

export default ProjectTemplate

export const query = graphql`
  query($id: String!) {
    contentfulProjects(id: { eq: $id }) {
      id
      title
      subtitle
      url
      slug
      description {
        raw
      }
      featuredImage {
        fluid(maxWidth: 1200 , quality: 60) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`